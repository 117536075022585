export class ConsoleService {
  static isStencilWarning(...args: any[]): boolean {
    return args[0]?.startsWith('STENCIL');
  }

  /**
   * A whitelist of errors and warnings that should not be printed to the
   * console if the verbosity level is set to `no-whitelist`. Only include
   * errors and warnings that are harmless or outside of our control. Each
   * predicate in a list takes the same arguments as the original console
   * method.
   */
  static get whitelist(): Record<string, Array<(...args: any[]) => boolean>> {
    return {
      warn: [this.isStencilWarning.bind(this)],
      error: [],
    };
  }

  /**
   * Control the verbosity of the console output.
   */
  static setVerbosity(verbosity: 'quiet' | 'no-whitelist' | 'default'): void {
    if (verbosity === 'default') {
      return;
    }

    // Suppress all warnings and errors
    if (verbosity === 'quiet') {
      ['warn', 'error'].forEach((method) => this.adjustConsoleMethod(method as 'warn' | 'error', () => true),
      );
    }

    // Do not print warnings and errors that are whitelisted
    if (verbosity === 'no-whitelist') {
      Object.entries(this.whitelist).forEach(([method, predicates]) => this.adjustConsoleMethod(method as 'warn' | 'error', (...args) => (predicates as Array<(...args: any[]) => boolean>).some((predicate) => predicate(...args),
      ),
      ),
      );
    }
  }

  /**
   * Adjust a console `method` such that messages for which the `predicate`
   * returns `true` are not printed. The `predicate` takes the same arguments as
   * the original console method.
   */
  static adjustConsoleMethod(
    method: 'warn' | 'error',
    predicate: (...args: any[]) => boolean,
  ): void {
    // eslint-disable-next-line no-console
    const originalConsoleMethod = console[method];

    // eslint-disable-next-line no-console
    console[method] = (...args: any[]) => {
      if (predicate(...args)) {
        return;
      }

      originalConsoleMethod(...args);
    };
  }
}
